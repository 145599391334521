// src/components/ExperienceCard.js
import React from "react";
import "./ExperienceCard.css"; // Import your custom styles

const ExperienceCard = ({ logo, companyName, companyType, period, role, description, skills }) => {
  return (
    <div className="experience-card">
      <div className="experience-header">
        <img src={logo} alt={`${companyName} Logo`} className="company-logo bounce-in" />
        <div className="company-info bounce-in">
          <h4>
            {companyName} <span className="company-type bounce-in">{companyType}</span>
          </h4>
          <span className="experience-date bounce-in">{period}</span>
        </div>
      </div>
      <b className="experience-role bounce-in">{role}</b>
      {/* Render description as a bullet-point list if it's an array */}
      {Array.isArray(description) ? (
        <ul className="experience-description bounce-in">
          {description.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      ) : (
        <p className="experience-description bounce-in">{description}</p>
      )}
      <p className="experience-skills bounce-in">
        <b>Skills:</b> {skills}
      </p>
    </div>
  );
};

export default ExperienceCard;
