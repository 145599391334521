import React from "react";
import "./Footer.css"; // Assuming you are using a CSS file for styles
import { FaLinkedin, FaInstagram, FaTiktok, FaGithub } from "react-icons/fa"; // Importing social media icons
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer">
      {/* Social Media Icons */}
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/ceoturobov/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="icon" />
        </a>
        <a href="https://github.com/Muyinjon" target="_blank" rel="noopener noreferrer">
          <FaGithub className="icon" />
        </a>
        <a href="https://www.instagram.com/muindollar" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="icon" />
        </a>
        <a href="https://www.tiktok.com/@muindollar" target="_blank" rel="noopener noreferrer">
          <FaTiktok className="icon" />
        </a>
        <a href="https://x.com/RealRapperM" target="_blank" rel="noopener noreferrer">
          <FaXTwitter className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
