// src/pages/ProjectsPage.js
import React from "react";
import ProjectSection from "../sections/ProjectSection";

const ProjectsPage = () => {
  return (
    <div className="projects-page">
      <h1 className="projects-title">My Projects</h1>
      <div className="projects-list"></div>
      <ProjectSection />
    </div>
  );
};

export default ProjectsPage;
