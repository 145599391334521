// src/components/ProfileCard.js
import React, { useEffect, useState } from "react";
import "./ProfileCard.css"; // Correct import path
import profileImage from "../assets/images/profile.png"; // Correct import path
import anime from "animejs/lib/anime.es.js"; // Import Anime.js
import ScrambleText from "../styles/effects/ScrambleText";
import { FaLinkedin, FaInstagram, FaTiktok, FaEnvelope } from "react-icons/fa"; // Importing social media icons

const ProfileCard = () => {
  const [isHovered, setIsHovered] = useState(false); // State to track hover effect
  const [isRotated, setIsRotated] = useState(false);

  // Function to rotate the profile block
  const handleRotate = () => {
    setIsRotated((prev) => !prev); // Toggle the rotation state on each click
  };
  useEffect(() => {
    // JavaScript code for staggered animation using Anime.js
    const staggerVisualizerEl = document.querySelector(".stagger-visualizer");
    const fragment = document.createDocumentFragment();
    const grid = [8, 6];
    const col = grid[1];
    const row = grid[0];
    const numberOfElements = col * row;

    for (let i = 0; i < numberOfElements; i++) {
      fragment.appendChild(document.createElement("div"));
    }

    staggerVisualizerEl.appendChild(fragment);

    const staggersAnimation = anime
      .timeline({
        targets: ".stagger-visualizer div",
        easing: "easeInOutSine",
        delay: anime.stagger(50),
        loop: false, // Set to false to run only once
        autoplay: false,
      })

      .add({
        translateX: () => anime.random(-20, 20),
        translateY: () => anime.random(-20, 20),
        opacity: 0, // Add fade-out effect
        duration: 500, // Duration for both translation and fade-out
        easing: "easeOutQuad", // Smooth easing for fade-out
        delay: anime.stagger(10, { from: "last" }), // Maintain staggered delay
      });

    staggersAnimation.play();
  }, []); // Run once on mount

  return (
    <div className="profile-card">
      <div className="profile-container">
        {/* Animation Container */}
        <div className="stagger-visualizer"></div>

        {/* Profile Content */}
        {/* <div className="name-ribbon">Muyinjon (M) Turobov</div> */}

        <div className={`profile-block ${isRotated ? "rotate" : ""}`} onClick={handleRotate}>
          <img src={profileImage} alt="Profile" className="profile-picture rainbow-border-rounded " />
        </div>
      </div>

      <h1 className="combined-effect Hello-text ">Hello, I Am</h1>
      <div className="rainbow-text-container">
        <h2 className="rainbow-text">Muyinjon (M) Turobov</h2>
      </div>
      <i className="description">Aspiring Product Manager | Skilled in Data, AI, and Growth Strategy | Passionate About Tech and Business Innovation</i>
      <div className="profile-actions">
        <a className="action-btn linkbutton " href="mailto:Ceoturobov@gmail.com" data-text="Email Me">
          <FaEnvelope className="button-icon" />
          <ScrambleText text={"Email Me"} />
        </a>

        {/* LinkedIn Button */}
        <a className="action-btn linkbutton" href="https://www.linkedin.com/in/ceoturobov/" target="_blank" rel="noopener noreferrer" data-text="Linkedin">
          <FaLinkedin className="button-icon" />
          <ScrambleText text={"Linkedin"} />
        </a>
      </div>
    </div>
  );
};

export default ProfileCard;
