// src/pages/HomePage.js
import React from "react";
import ProfileSection from "../sections/ProfileSection";
import ExperienceSection from "../sections/ExperienceSection";
import EducationSection from "../sections/EducationSection";
import ProjectSection from "../sections/ProjectSection";

const HomePage = () => (
  <div className="home-page">
    <ProfileSection />
    <ExperienceSection />
    <EducationSection />
    <ProjectSection />
  </div>
);

export default HomePage;
