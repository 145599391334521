// src/sections/ProjectSection.js
import React from "react";
import ProjectCard from "../components/ProjectCard";
import "./ProjectSection.css"; // Import appropriate styles
import Dislexia from "../assets/images/dislexia.png";
import CASlogo from "../assets/images/caslogo.svg";
import GTALogo from "../assets/images/gta.jpeg";
const projectData = [
  {
    title: "Dislexia Chrome Extension",
    description:
      "A Chrome extension designed to assist users with dyslexia by providing text-to-speech (TTS) and speech-to-text (STT) features, as well as an overlay for text highlighting.",
    technologies: ["JavaScript", "Chrome Extensions API", "HTML", "CSS", "Web Speech API"],
    link: "https://github.com/Muyinjon/Dislexia",
    image: Dislexia,
  },
  {
    title: "Central Asian Success",
    description:
      "A 501(c)(3) non-profit organization dedicated to empowering young Central Asians in America by providing mentorship, networking opportunities, and fostering a supportive community to help each member reach their fullest potential.",
    technologies: ["Website Development", "Social Media Platforms", "Online Communication Tools", "Content Creation"],
    link: "/projects-folder/Central_Asian_Success/CAS.html", // Replace with the actual link when available
    image: CASlogo, // Replace with the actual path or URL to the logo/image
  },
  {
    title: "Grand Theft Auto VI Store",
    description:
      "Grand Theft Auto VI heads to the state of Leonida, home to the neon-soaked streets of Vice City and beyond in the biggest, most immersive evolution of the Grand Theft Auto series yet. Coming 2025 to PlayStation 5 and Xbox Series X|S.",
    technologies: ["Angular", "TypeScript", "Node.js", "HTML ", "CSS "],
    link: "https://muindollar.com/",
    image: GTALogo,
  },
  // Add more project data here as needed
];

const ProjectSection = () => {
  return (
    <section className="project-section bounce-in">
      <h3>📁 My Projects</h3>
      <p className="project-intro bounce-in">Showcasing a diverse range of projects, highlighting skills in development, design, and problem-solving.</p>
      {projectData.map((project, index) => (
        <div className="bounce-in" key={index}>
          <ProjectCard image={project.image} title={project.title} description={project.description} technologies={project.technologies} link={project.link} />
        </div>
      ))}
    </section>
  );
};

export default ProjectSection;
