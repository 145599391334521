// src/pages/ContactPage.js
import React from "react";
import { FaLinkedin, FaGithub, FaEnvelope, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Us</h1>
      <div className="social-contact">
        <h2>Social Media</h2>
        <ul className="social-links">
          <li>
            <a href="https://www.linkedin.com/in/ceoturobov/">
              <FaLinkedin className="icon" /> LinkedIn
            </a>
          </li>
          <li>
            <a href="https://github.com/Muyinjon">
              <FaGithub className="icon" /> GitHub
            </a>
          </li>
          <li>
            <a href="https://x.com/RealRapperM">
              <FaXTwitter className="icon" /> Twitter
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/muindollar">
              <FaInstagram className="icon" /> Instagram
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@muindollar">
              <FaTiktok className="icon" /> TikTok
            </a>
          </li>
        </ul>
        <h2>
          <FaEnvelope className="icon" /> Email:{" "}
          <a href="mailto:Ceoturobov@gmail.com" target="_blank" rel="noopener noreferrer" className="contact-link">
            Ceoturobov@gmail.com
          </a>
        </h2>
        <h2>
          Call me at:{" "}
          <a href="tel:+13474354914" className="contact-link">
            +1 (347) 435 - 4914
          </a>
        </h2>
      </div>
    </div>
  );
};

export default ContactPage;
