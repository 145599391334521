// src/components/EducationCard.js
import React from "react";
import "./EducationCard.css"; // Import appropriate styles

const EducationCard = ({ logo, institutionName, location, period, degree, gpa, details }) => {
  return (
    <div className="education-card bounce-in">
      <div className="education-header bounce-in">
        <img src={logo} alt={`${institutionName} Logo`} className="institution-logo bounce-in" />
        <div className="institution-info bounce-in">
          <h4>{institutionName}</h4>
          <p className="institution-location bounce-in">{location}</p>
          <span className="education-date bounce-in">{period}</span>
        </div>
      </div>
      <p className="degree bounce-in">{degree}</p>
      <p className="gpa bounce-in">GPA: {gpa}</p>
      <ul className="education-details bounce-in">
        {details.map((detail, index) => (
          <li key={index}>{detail}</li>
        ))}
      </ul>
    </div>
  );
};

export default EducationCard;
