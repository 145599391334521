// src/components/ProjectCard.js
import React from "react";
import "./ProjectCard.css"; // Import appropriate styles

const ProjectCard = ({ title, description, technologies, link, image }) => {
  return (
    <div className="project-card">
      <img src={image} alt={title} className="project-image bounce-in" />
      <h4 className="project-title bounce-in">{title}</h4>
      <p className="project-description bounce-in">{description}</p>
      <p className="project-technologies bounce-in">
        <b>Technologies:</b> {technologies.join(", ")}
      </p>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer" className="project-link">
          View Project
        </a>
      )}
    </div>
  );
};

export default ProjectCard;
