import React, { useState, useEffect } from "react";

const ScrambleText = ({ text }) => {
  const [displayText, setDisplayText] = useState(text);
  const originalText = text;

  // Define all characters: letters (uppercase and lowercase), numbers, and symbols
  const characters = "abcdefghijklmnopqrstuvwxyz";

  const handleMouseOver = () => {
    let iterations = 0;

    const interval = setInterval(() => {
      setDisplayText((prevText) =>
        prevText
          .split("")
          .map((char, index) => {
            if (index < iterations) {
              return originalText[index];
            }
            return characters[Math.floor(Math.random() * characters.length)];
          })
          .join("")
      );

      if (iterations >= originalText.length) {
        clearInterval(interval);
      }

      iterations += 1 / 2; // Adjust speed if necessary
    }, 40);
  };

  const handleMouseOut = () => {
    // Reset to original text
    setDisplayText(originalText);
  };

  return (
    <span
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ fontSize: "24px", cursor: "pointer" }}
    >
      {displayText}
    </span>
  );
};

export default ScrambleText;
