// src/sections/ProfileSection.js
import React from 'react';
import ProfileCard from '../components/ProfileCard';
import './ProfileSection.css'; // Create appropriate styles

const ProfileSection = () => {
  return (
    <section className="profile-section bounce-in">
      {/* Add Header Component or Other Elements Here */}
      <ProfileCard />
    </section>
  );
};

export default ProfileSection;
