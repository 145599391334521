// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProfileSection from "./sections/ProfileSection";
import ExperienceSection from "./sections/ExperienceSection";
import EducationSection from "./sections/EducationSection";
import ProjectSection from "./sections/ProjectSection";
import BackToTop from "./components/BackToTop";
import Footers from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import ContactPage from "./pages/ContactPage";
import "./styles/App.css"; // Import global styles
import "./styles/bounceEffect.css"; // Import the bounce effect CSS
import "animate.css";
import "./styles/rainboweffects.css";

function App() {
  // State for dark mode, initialized to true to make dark mode the default
  const [darkMode, setDarkMode] = useState(true);

  // Effect to apply dark mode based on localStorage preference
  useEffect(() => {
    // Check if there's a saved preference in localStorage
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode === null) {
      // If no preference is saved, default to dark mode
      localStorage.setItem("darkMode", "true");
    } else {
      // If there is a saved preference, use it
      setDarkMode(savedDarkMode === "true");
    }
  }, []);
  // Apply the theme to the body class dynamically
  useEffect(() => {
    document.body.className = darkMode ? "dark-mode" : "light-mode";
  }, [darkMode]);

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode); // Save preference
      return newMode;
    });
  };
  //bounce effect
  useEffect(() => {
    const elements = document.querySelectorAll(".bounce-in"); // Select elements with the bounce-in class

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show"); // Add 'show' class to trigger the animation
            observer.unobserve(entry.target); // Stop observing once the element is shown
          }
        });
      },
      {
        threshold: 0.16, // Trigger animation when 16% of the element is visible
      }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <Router>
      {" "}
      {/* Ensure Router wraps the entire app */}
      <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`}>
        {/* Dark Mode Toggle Button */}
        <button className="toggle-button" onClick={toggleDarkMode}>
          {darkMode ? "Light Mode" : "Dark Mode"}
        </button>

        {/* Sections */}
        <Header></Header>
        <BackToTop></BackToTop>
        <Routes>
          {" "}
          {/* Routes should be inside Router */}
          <Route path="/" element={<HomePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footers />
      </div>
    </Router>
  );
}

export default App;
